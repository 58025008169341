.block-enter-active,
.block-leave-active {
	transition: 200ms;
	max-height: 250;
	overflow: hidden;
}

.block-enter,
.block-leave-to {
	opacity: 0;
	transform: scale(0.5);
	max-height: 0;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	border-top-width: 0;
	border-bottom-width: 0;
}

.dragging-block-move {
	transition: transform 0.5s;
}