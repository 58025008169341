
					@import './src/sass/variables';
					@import './src/sass/mixins';
				


































































.home.view {
	& > .notices {
		position: absolute;
		bottom: 60px;
		left: 60px;
		list-style-type: none;
	}

	@include breakpoint-min(medium) {
		.courses {
			display: flex;
			flex-flow: row wrap;
			max-width: 1360px;
			margin: 0 auto;

			.active,
			.completed {
				padding-left: $default_padding * 2;
				padding-right: $default_padding * 2;
				flex: 0 1 calc(50%);
			}
		}
	}
}
