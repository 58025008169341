
					@import './src/sass/variables';
					@import './src/sass/mixins';
				






































.progress-bar {
	display: flex;
	width: calc(100% + #{$default_padding * 2});
	background-color: $color__semi_dark;
	height: 3px;
	font-size: 0.75rem;
	margin: 1.75em -#{$default_padding} 0;

	& > div {
		position: relative;
		color: $color__light_blue;
		justify-content: flex-end;
		text-align: right;
		white-space: nowrap;
		background-color: $color__light_blue;
		transition: width .3s ease;

		span {
			position: absolute;
			top: -1.5em;
		}
	}
}
