
					@import './src/sass/variables';
					@import './src/sass/mixins';
				











































label {
	display: block;
	font-size: 1.25em;
	margin-bottom: 1em;
}

input {
	font-family: monospace;
	letter-spacing: 1px;
	margin-bottom: $default_padding;
	text-transform: uppercase;
}
