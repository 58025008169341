.vm--modal {
	color: $color__text;
	overflow: visible !important;

	&:not(.vue-dialog),
	.vue-dialog-content {
		padding: $default_padding;
	}

	.vue-dialog-content-title {
		font-size: 1.25rem;
	}

	.vue-dialog-content {
		font-size: $font__size;
	}

	input:not(.vs__search) {
		box-shadow: 1px 1px 10px -4px rgba(0, 0, 0, 0.1);
		border: $border;
	}

	.vs__search::placeholder,
	.vs__dropdown-toggle,
	.vs__dropdown-menu,
	.bool-input .input {
		box-shadow: none;
		border: $border;
	}

	.range input {
		box-shadow: none;
	}

	.modal-footer {
		margin-top: $default_padding;
		display: flex;
		flex-flow: row nowrap;

		.button:last-child {
			margin-left: auto;
		}
	}

	.vue-dialog-button {
		font-size: $font__size;

		&.danger {
			color: $color__red;
		}
	}

	.modal-inner {
		position: relative;
		display: flex;
		flex-flow: column nowrap;
		height: 100%;

		& > .close {
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
		}

		.modal-content {
			& > label:not(.bool-input) {
				display: block;
				margin-bottom: 5px;
			}
		}

		.modal-footer {
			margin-top: auto;
		}
	}

	&.settings-modal {
		overflow: visible;
	
		.settings-modal-inner {
			position: relative;
			display: flex;
			flex-flow: column nowrap;
			height: 100%;
	
			& > .close {
				position: absolute;
				top: 0;
				right: 0;
				cursor: pointer;
			}
	
			label {
				display: block;
			}
	
			.modal-footer {
				margin-top: auto;
			}
		}
	}
}


