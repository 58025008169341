
					@import './src/sass/variables';
					@import './src/sass/mixins';
				










































































































.account-menu {
	position: fixed;
	top: $default_padding * 2 - 10px;
	right: $default_padding * 3 - 10px;
	text-align: right;
	z-index: 200;
	user-select: none;

	&:focus {
		outline: none;
	}

	&.open nav {
		right: 0;
		opacity: 1;
		transition: right 0s, opacity .2s ease-out;
	}

	& > svg {
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 110;
		background-color: $color__white;
		border-radius: 50%;
	}

	nav {
		position: absolute;
		right: -9999px;
		opacity: 0;
		transition: opacity .2s ease-out, right 0s linear .2s;
		border-radius: $border_radius;
		background-color: $color__white;
		box-shadow: $box_shadow;
		z-index: 100;
	}

	ul {
		list-style: none;
		text-align: left;
		padding-left: 0;
	}

	li {
		display: block;

		&.head {
			padding-right: 65px;
			white-space: pre;
		}

		& > * {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			padding: 15px;
			line-height: 28px;
		}

		& > span {
			cursor: default;
		}

		& > a {
			cursor: pointer;
			color: inherit;
			text-decoration: inherit;

			&:hover {
				background-color: $color__hover;
			}
		}

		&:not(:last-child) {
			border-bottom: $border;
		}
	}
}

@include breakpoint(small) {
	.account-menu {
		right: $site__padding / 2;
	}
}
