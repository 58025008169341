
					@import './src/sass/variables';
					@import './src/sass/mixins';
				


























































































.quiz-timer::v-deep {
	.progress-bar {
		& > div span {
			top: 1em;

			@include breakpoint('small') {
				top: 0.5em;
			}
		}
	}
}
