$vs-component-line-height: 1.7;

@import '~vue-select/src/scss/vue-select.scss';

.vs__search,
.vs__search:focus {
	margin-top: 0;
	border: none !important;

	&::placeholder {
		border: none !important;
		padding: 0;
		color: rgba($color__dark, 0.5);
		font-size: 1.25rem;
	}
}

.vs__dropdown-toggle,
.vs__dropdown-menu {
	background: $color__white;
	font-size: 1.25rem;
	color: $color__text;
	box-shadow: $box_shadow__input;
	border-radius: $border_radius;
	padding: 0 1rem;
}

.v-select {
	margin-bottom: $default_padding / 2;

	.vs__dropdown-menu {
		padding: 0;
	}

	.vs__selected-options {
		align-items: center;

		& > .vs__selected {
			margin-right: 5px;
			border: none;
		}
	}

	.vs__dropdown-menu {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	.vs__selected,
	.vs__selected-options,
	.vs__dropdown-menu {
		margin: 0;
	}

	&.vs--single {
		.vs__selected,
		.vs__selected-options,
		.vs__dropdown-menu {
			padding: 0;
		}
	}
}

.vs__actions {
	padding: 2px 0 0 5px;
}
