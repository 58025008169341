
					@import './src/sass/variables';
					@import './src/sass/mixins';
				




































































.block-legend {
	position: fixed;
	bottom: $site__padding;
	right: $default_padding * 2;
	display: flex;
	flex-flow: column nowrap;
	align-items: stretch;
	z-index: 900;

	& > svg {
		display: block;
		border-radius: 50%;
		background: $color__white;
		padding: 10px;
		box-sizing: content-box;
		box-shadow: $box_shadow;
		cursor: pointer;
		transition: transform .1s ease-out;
		align-self: flex-end;
		background-color: $color__dark;
		color: $color__gray;
	}

	article {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		background-color: $color__white;
		box-shadow: $box_shadow;
		border-radius: $border_radius;
		margin-bottom: .3em;
		padding: .3em .6em;
		cursor: grab;
		font-size: 0;
		transition: font-size .1s ease-out;

		& > span {
			margin-right: 10px;
		}

		& > svg {
			margin-left: auto;
		}

		&:active {
			cursor: grabbing;
		}
	}

	&.open {
		article {
			font-size: 1.25rem;
		}

		& > svg {
			transform: rotate(45deg);
		}
	}
}
