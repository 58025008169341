
					@import './src/sass/variables';
					@import './src/sass/mixins';
				











































































































@include breakpoint(small) {
	.buttons {
		margin-top: 10px;
	}
}
