
					@import './src/sass/variables';
					@import './src/sass/mixins';
				









































































.notices {
	padding: 0 0 1em 0;

	.notice {
		color: $color__white;
		padding: 0.75em;
		margin-bottom: 1em;
		border-radius: $border_radius;

		a {
			color: $color__white;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.success {
		background: $color__green;
	}

	.info {
		background: $color__cyan;
	}

	.error {
		background: $color__red;
	}
}
