
					@import './src/sass/variables';
					@import './src/sass/mixins';
				























































.modal-content {
	margin-bottom: $default_padding;
}
