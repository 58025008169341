.grow-enter-active,
.grow-leave-active {
	transition: 200ms;
}

.grow-enter,
.grow-leave-to {
	opacity: 0;
	font-size: 0;
	padding: 0;
	margin: 0;
}