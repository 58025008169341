
					@import './src/sass/variables';
					@import './src/sass/mixins';
				























































































































































.block-area-wrapper {
	display: flex;
	flex-flow: column nowrap;
	flex: 1;
}

.quiz-timer::v-deep {
	margin-top: auto;

	.progress-bar {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
}

.block-area {
	counter-reset: question;
}

.timed-quiz-notice::v-deep {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	transition: left 0.2s ease;
	padding: $default_padding * 2;
	margin: 0 $default_padding;
	border-radius: $border_radius;
	background-color: $color__white;
	box-shadow: $box_shadow;
	max-width: 480px;
	width: 100%;

	p {
		line-height: 2em;
	}
}

.sidebar.active + .content {
	.timed-quiz-notice {
		left: 66%;
	}
}
