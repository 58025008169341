input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='search']:not(.vs__search),
input[type='tel'],
input[type='number'],
input[type='date'],
input[type='month'],
input[type='week'],
input[type='time'],
input[type='datetime'],
input[type='datetime-local'],
input[type='color'],
textarea {
	background: $color__white;
	font-size: 1.25rem;
	width: 100%;
	border: none;
	box-shadow: $box_shadow__input;
	border-radius: $border_radius;
	padding: 0.5rem 1rem;
	margin-bottom: $default_padding / 2;
	line-height: 1em;
	outline: none !important;
	transition: box-shadow 0.2s ease-out;

	&:focus {
		@include inner-border(1px, $color__light-blue);
	}

	&.error {
		background: $color__semi_red;
		@include inner-border(1px, $color__red);
	}
}

input[type='range'] {
	background: $color__white;
	font-size: 1.25rem;
	width: 100%;
	border: none;
	box-shadow: none;
	padding: 0.75rem 0;
	margin-bottom: $default_padding / 2;
	line-height: 1em;
	outline: none !important;
}

textarea {
	resize: vertical;
	line-height: 1.25em;
}

.inline-label {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	background: $color__white;
	box-shadow: $box_shadow__input;
	border-radius: $border_radius;
	overflow: hidden;
	margin-bottom: 10px;

	&.inline-flex {
		display: inline-flex;

		span:last-child {
			margin-right: 10px;
		}

		input[type='number'] {
			max-width: 125px;

			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}
	}

	span:first-child {
		flex: 0 0 auto;
		font-size: 1.25rem;
		line-height: 40px;
		background: #f9f9f9;
		color: #666;
		padding: 0 1rem;
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-self: stretch;
	}

	div {
		width: 100%;
		padding: 0 1rem;
		font-size: 1.25rem;
		line-height: 40px;
	}

	input {
		margin: 0;
		box-shadow: none;
		background: none;
	}
}

.range {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	position: relative;

	input[type='range'] {
		flex: 1;
	}

	.current {
		position: absolute;
		top: 55%;
		left: 50%;
		transform: translateX(-50%);
	}

	.min,
	.max {
		position: relative;
		top: -7px;
	}

	.min {
		margin-right: $default_padding / 2;
	}

	.max {
		margin-left: $default_padding / 2;
	}
}
