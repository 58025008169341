
					@import './src/sass/variables';
					@import './src/sass/mixins';
				

































































































p {
	margin-bottom: 1rem;
}

pre {
	display: inline;
}

table {
	margin-bottom: 0;
}

th, td {
	padding-left: 0;
	padding-right: 0;
	border: none;
}

.course-name {
	padding-right: $default_padding;
}

.course-actions {
	text-align: right;
}

.buttons {
	position: absolute;
	top: $default_padding;
	right: $default_padding;
}
