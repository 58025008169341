
					@import './src/sass/variables';
					@import './src/sass/mixins';
				


















































































































.modal-content {
	margin-bottom: $default_padding;
}

p {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	column-gap: 10px;
	margin-bottom: $default_padding;

	label {
		flex: 1 0 100%;
	}

	input {
		flex: 1;
		margin-bottom: 0;
	}
}
