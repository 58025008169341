html,
body {
	height: 100%;
}

body {
	background: $color__background;
	margin: 0;
	font-size: $font__size;
	font-family: $font__text;
	color: $color__text;
	font-weight: $font__normal;
	overflow-y: scroll;
}

#app {
	min-height: 100%;
}

#app,
.layout {
	display: flex;
	flex-flow: column;
	flex: 1;
}

input,
textarea,
select,
button,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: inherit;
	font-size: 1em;
	color: inherit;
	font-weight: inherit;
}

p,
li,
small {
	line-height: 1.2em;
}

header {
	line-height: 1.8em;
}

.site-header {
	margin-bottom: $default_padding * 2;
}

h1,
h2 {
	font-size: 1.625rem;
	line-height: 1.25em;
	margin-bottom: 1em;
}

nav {
	ul,
	ol {
		list-style-type: none;
	}
}

a {
	color: $color__link;
	text-decoration: underline;
	cursor: pointer;

	&:hover {
		color: rgba($color__light_blue, 0.8);
	}
}
