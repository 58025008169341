.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 300;
	background-color: $color__dark;
	color: $color__white;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	width: $sidebar__width_active;
	transition: width 0.2s ease-out;
	height: 100%;
	overflow: hidden;

	& > .menu-button {
		position: absolute;
		top: $default_padding * 2;
		right: $default_padding;
		color: $color__semi_white2;

		cursor: pointer;

		svg {
			display: block;
		}
	}

	&:not(.active) {
		width: $sidebar__width;

		.hide-small {
			display: none !important;
		}

		footer {
			padding: 0;
			margin-bottom: $sidebar__padding;
			justify-content: center;

			button {
				transform: rotate(0deg);
			}
		}
	}

	header {
		padding: $sidebar__padding $sidebar__padding $sidebar__padding / 2;
		text-align: center;
		overflow: hidden;

		h1 {
			display: inline-block;
			color: $color__white;
			font-weight: $font__bold;
			margin: 0;
		}
	}

	.button.secondary {
		a,
		button {
			color: rgba($color: $color__white, $alpha: 0.7);
		}
	}

	.sidebar-content,
	footer {
		padding: $sidebar__padding / 2 $sidebar__padding;
	}

	.sidebar-content {
		overflow-x: hidden;
		overflow-y: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		ol,
		ul {
			list-style: none;
		}

		a {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			padding: $default_padding / 2 0;
			color: inherit;
		}
	}

	li {
		position: relative;
		transition: color 0.1s ease;
		color: rgba($color: $color__white, $alpha: 0.7);

		span,
		a {
			cursor: pointer;

			&:hover {
				color: $color__white;
			}
		}
	}

	.highlight {
		display: none;
		position: absolute;
		z-index: -1;
		pointer-events: none;
		top: 0;
		right: -$site__padding;
		width: $sidebar__width_active;
		border-top: 0.75em solid $color__semi_white;
		border-bottom: 0.75em solid $color__semi_white;
		border-right: 15px solid $color__background;
	}

	a {
		text-decoration: none;
		transition: color 0.1s ease;

		svg {
			margin-right: $default_padding;
		}

		.highlight {
			border-top-width: calc(1em + 10px);
			border-bottom-width: calc(1em + 10px);
			border-right: none;
		}
	}

	li.active:not(.sortable-drag),
	.router-link-exact-active {
		cursor: default;

		& > .highlight {
			display: block;
		}
	}

	footer {
		margin-top: auto;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-end;
		align-items: center;

		.back-link {
			margin-right: auto;
		}

		button {
			padding: 0.5rem 1rem;
			transform: rotate(90deg);
		}

		a,
		button {
			transition: color 0.1s ease;
			color: $color__semi_white2;
		}

		a:hover,
		.button.secondary a:hover,
		.button.secondary button:hover {
			color: $color__white;
		}
	}
}

@include breakpoint('large') {
	.sidebar.active {
		width: $sidebar__width_active;
		max-width: 100%;
	}
}

@include breakpoint('tiny') {
	.sidebar > .menu-button {
		top: 20px;
		right: 8px;

		svg {
			width: 24px;
			height: 24px;
		}
	}
}
