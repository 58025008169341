
					@import './src/sass/variables';
					@import './src/sass/mixins';
				

































































































.edit-controls {
	padding: 0 $sidebar__padding;
	display: flex;
	flex-flow: row nowrap;
	margin: 0 -4px;

	.button.secondary {
		padding: 0 2px;

		a, button {
			background-color: rgba($color: $color__white, $alpha: 0.3);
			color: $color__dark;
			transition: background-color .1s ease-out;

			&:hover {
				background-color: rgba($color: $color__white, $alpha: 0.5);
			}
		}
	}

	&.adding, &.updating {
		button {
			height: 100%;
		}
	}

	input {
		margin: 0 10px;
		color: $color__text;
	}
}
