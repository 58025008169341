
					@import './src/sass/variables';
					@import './src/sass/mixins';
				
































































































































article {
	position: relative;
	background: $color__background;
	outline: 1px dashed $border_color;
	min-height: $site__padding * 4;

	& > .block-inner {
		padding: $site__padding $site__padding * 2;
	}

	&.image {
		min-height: $site__padding * 5;
	}
}

.action {
	position: absolute;
	left: 0;
	width: 80px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: rgba($color__dark, 0.5);
	transition: 200ms;
	z-index: 10;

	&:hover {
		color: $color__dark;
	}

	&.move {
		top: 0;
		cursor: grab;

		&:active {
			cursor: grabbing;
		}
	}

	&.settings {
		bottom: 0;
		cursor: pointer;
	}

	&.more {
		cursor: pointer;
		top: 0;
		right: 0;
		left: unset;

		svg {
			transform: rotate(90deg);
		}

		.actions-dropdown {
			list-style-type: none;
			background-color: $color__white;
			border-radius: $border_radius;
			box-shadow: $box_shadow__input;
			padding: 10px;
			margin: 0;
			position: absolute;
			top: 75%;
			left: 0;
			transform: translateX(-30%);
			min-width: 100px;
			text-align: center;

			li {
				color: rgba($color__dark, 0.5);
				transition: 200ms;
				line-height: 1.5em;

				&:hover {
					color: $color__dark;
				}
			}

			&:not(.open) {
				display: none;
			}
		}
	}
}

.block-type {
	position: absolute;
	bottom: 5px;
	right: 10px;
	text-align: center;
	text-transform: uppercase;
	font-size: 0.75em;
	opacity: 0.5;
	cursor: inherit;

	svg {
		margin-right: 10px;
	}
}
