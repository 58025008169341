
					@import './src/sass/variables';
					@import './src/sass/mixins';
				































.card {
	margin-bottom: $default_padding * 2;

	.card-inner {
		background: $color__white;
		border-radius: $border_radius;
		box-shadow: $box_shadow;
		padding: $default_padding;
		position: relative;

		header {
			font-size: 20px;
			line-height: 24px;
			margin-bottom: $default_padding / 2;
		}

		footer {
			margin-top: 15px;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: space-between;
		}

		form input {
			box-shadow: none;
			border: $border;
		}

		.buttons {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-end;
			margin: 0 $default_padding / -2;
			flex: 1;

			.button {
				padding: 0 $default_padding / 2;

				&.left {
					flex: 1;
				}
			}
		}
	}
}
