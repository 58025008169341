
					@import './src/sass/variables';
					@import './src/sass/mixins';
				























.course-layout {
	padding: $site__padding $site__padding / 2;
}

@include breakpoint-min(small) {
	.course-layout {
		padding: $site__padding;
	}
}
