
					@import './src/sass/variables';
					@import './src/sass/mixins';
				






























.block-area-wrapper {
	display: flex;
	flex-flow: column nowrap;
	flex: 1;
}

.block-area {
	counter-reset: question;
}

.sidebar.active + .content {
	.timed-quiz-notice {
		left: 66%;
	}
}
