table {
	width: 100%;
	max-width: 1000px;
	text-align: left;
	margin-bottom: $site__padding;

	td {
		border: $border;

		&.cta > div {
			display: flex;
			flex-flow: row wrap;
			gap: 10px;
			justify-content: flex-end;
			box-sizing: border-box;

			.button {
				margin-left: 0 !important;
			}

			a,
			button {
				line-height: 2em;
			}
		}
	}

	th,
	td {
		text-align: left;
		padding: 0.75em 1em;
	}
}

.full-width table {
	th,
	td {
		&:first-child {
			padding-left: $site__padding * 2;
		}

		&:last-child {
			padding-right: $site__padding * 2;
		}
	}
}

.resize-cursor {
	cursor: col-resize;

	td p:nth-last-child(2) {
		margin: 0;
	}
}
