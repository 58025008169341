
					@import './src/sass/variables';
					@import './src/sass/mixins';
				




































































































































































































.review-course {
	flex: 1;
	display: flex;
	flex-flow: column;
}

header::v-deep {
	.button {
		margin-top: $default_padding;
	}
}

.downloads {
	display: flex;
	flex-flow: row wrap;
	gap: $default_padding;
}

.content::v-deep {
	flex: 1;
	display: flex;
	flex-flow: column;

	& > header {
		padding: $default_padding $default_padding * 2;
		border-bottom: $border;

		@include breakpoint-min('small') {
			padding: $default_padding * 2 $default_padding * 4;
		}

		.headline {
			font-size: 1.625rem;
			line-height: 1.5em;
		}
	}

	p,
	ol,
	ul {
		margin-bottom: $line__height;

		&:last-child {
			margin-bottom: 0;
		}
	}

	table p {
		margin-bottom: 0;
	}

	.block-inner {
		input, select, textarea, .options label, .bool-input, .items li, &.disabled li {
			user-select: none;
			pointer-events: none;
			cursor: pointer;
		}
	}
}

.content-footer {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	border-top: $border;
	padding: $default_padding $default_padding * 2;

	@include breakpoint-min('small') {
		padding: $default_padding * 2 $default_padding * 4;
	}

	.logo-wrapper {
		padding: 0;
	}
}

nav {
	display: flex;
	flex-flow: row nowrap;

	.button::v-deep {
		margin-left: 10px;
	}
}

.notices::v-deep {
	position: fixed;
	left: calc(#{$sidebar__width_active} + #{$site__padding});
	bottom: $site__padding;
	z-index: 100;
}
