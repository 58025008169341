
					@import './src/sass/variables';
					@import './src/sass/mixins';
				


















.review-layout {
	.view > {
		main {
			margin-left: $sidebar__width;
			transition: margin-left 0.2s ease;
		}

		@include breakpoint-min('large') {
			.sidebar.active + main {
				margin-left: $sidebar__width_active;
			}
		}
	}
}
