
					@import './src/sass/variables';
					@import './src/sass/mixins';
				












































































































.view {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	height: 100%;

	.card {
		max-width: 400px;
		width: 100%;
	}

	form {
		overflow: hidden;
	}

	.button {
		float: right;
		clear: both;
	}

	.terms {
		a {
			color: $color__dark;
			text-decoration: none;
			font-weight: $font__semibold;
		}
	}
}
