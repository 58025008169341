$page_pace: 200ms;

.page-enter-active,
.page-leave-active {
	transition: opacity $page_pace;
}

.page-enter-active {
	transition-delay: $page_pace;
}

.page-enter,
.page-leave-to {
	opacity: 0;
}

.page-enter {
	visibility: hidden;
}

.page-enter-to,
.page-leave {
	opacity: 1;
}