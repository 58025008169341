
					@import './src/sass/variables';
					@import './src/sass/mixins';
				





































.logo-wrapper {
	padding: 0 $site__padding * 2;

	.logo {
		max-width: 222px;
	}

	object,
	.placeholder {
		pointer-events: none;
	}

	.placeholder {
		display: flex;
		width: 222px;
		height: 40px;
		outline: 2px dashed $border_color;
		align-items: center;
		justify-content: center;

		&::after {
			content: 'Logotype here';
			text-transform: uppercase;
		}
	}
}
