
					@import './src/sass/variables';
					@import './src/sass/mixins';
				




































































































































































































h2 {
	margin-bottom: 0.5em;
}

p {
	margin-bottom: $default_padding;
}

ul {
	padding: 0;
	list-style-type: none;
}

li::v-deep {
	margin-bottom: $default_padding;

	.v-select {
		margin-bottom: 0;
	}
}

small {
	font-style: italic;
}

.public-link {
	display: flex;
	flex-flow: row nowrap;
	margin-bottom: $default_padding / 2;

	input {
		margin-bottom: 0;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right-width: 0;
	}

	span {
		border-top-right-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		border: $border;
		padding: 10px;
		background-color: $color__gray;
		cursor: pointer;
		transition: background-color 0.1s ease;

		&:hover {
			background-color: darken($color: $color__gray, $amount: 5%)
		}
	}
}
