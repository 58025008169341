
					@import './src/sass/variables';
					@import './src/sass/mixins';
				
















































button {
	position: fixed;
	top: $default_padding * 2;
	right: $default_padding * 6;
	text-align: right;
	user-select: none;
	display: flex;
	align-items: center;
	width: 100px;
	height: 40px;
	border-radius: 20px;
	background-image: linear-gradient(90deg, $color__link 50%, $color__white 50%);
	background-position: 0 0;
	transition: background-position 200ms;
	z-index: 10;
	outline: none;
	box-shadow: $box_shadow;

	&:hover {
		background-image: linear-gradient(90deg, $color__link 50%, $color__gray 50%);
	}
}

span {
	flex: 1;
	text-align: center;
	transition: color 200ms;
}

.preview {
	color: $color__white;
}

.edit-mode {
	background-position: 50px 0;

	.preview {
		color: inherit;
	}

	.edit {
		color: $color__white;
	}
}
