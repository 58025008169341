@mixin box-shadow($args...) {
	@if length($args) == 0 {
		$args: 0 2px 10px rgba(0, 0, 0, 0.1);
	}

	border-collapse: separate; // IE9
	-moz-box-shadow: $args;
	-webkit-box-shadow: $args;
	box-shadow: $args;
}

@mixin box-shadow-inset($args...) {
	@if length($args) == 0 {
		$args: 0 0 10px -5px $color__gray-3;
	}

	@include box-shadow(inset $args);
}

@mixin outer-border($width, $color) {
	@include box-shadow(0 0 0 $width $color);
}

@mixin inner-border($width, $color) {
	@include box-shadow-inset(0 0 0 $width $color);
}

@mixin question-counter() {
	&::before {
		counter-increment: question;
		content: counter(question);
		position: absolute;
		top: 50%;
		right: 100%;
		transform: translateY(-50%);
		font-weight: $font__bold;
		font-size: 2.25em;
		line-height: 1em;
		color: $color__semi_dark;
		text-align: right;
		white-space: nowrap;
		padding-right: $site__padding/2;
	}
}
