
					@import './src/sass/variables';
					@import './src/sass/mixins';
				












































































































$row_height: $font__size * 2;
$border_color: rgba($color__white, 0.3);
$active_background_color: rgba($color__white, 0.1);

li {
	display: flex;
	flex-wrap: wrap;
	line-height: $row_height;
	color: $color__semi_white2;

	&.dragging {
		opacity: 0.25;
	}

	&.draggable::v-deep {
		.router-link-exact-active::after {
			content: none;
		}
	}
}

.content {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	line-height: $row_height;
	position: relative;
	width: 100px; /* No idea why this works, but otherwise long texts will get broken. */

	.text {
		flex: 1 0 1em;
	}

	svg {
		align-self: center;
		justify-self: center;
	}
}

ol ol {
	width: 100%;
	list-style: none;
	padding-left: $site__padding/4;

	ol {
		padding-left: $site__padding/2;
	}

	li {
		padding-left: $site__padding/4;
		border-left: 1px solid $border_color;

		&::before {
			content: '';
			position: relative;
			height: $row_height/2;
			width: $site__padding/4;
			color: $color__dark;
			border-bottom: 1px solid $border_color;
			display: inline-block;
			left: -$site__padding/4;
		}

		&:last-child {
			border-left: none;

			&::before {
				border-left: 1px solid $border_color;
			}
		}
	}
}

.text {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.active {
	color: $color__white;
}

.highlight:not(.draggable-ghost) {
	border-top-width: $row_height/2;
	border-bottom-width: $row_height/2;
}

.handle {
	width: $row_height;
	text-align: center;
	cursor: grab;

	&:active {
		cursor: grabbing;
	}
}

.settings {
	width: $row_height;
	text-align: center;
}

.router-link-exact-active {
	& > span {
		color: #fff;
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		z-index: -1;
		pointer-events: none;
		top: 0;
		right: -40px;
		width: $sidebar__width_active;
		border-top: $row_height/2 solid $active_background_color;
		border-bottom: $row_height/2 solid $active_background_color;
	}
}

.insert-before > .content::before,
.insert-after > .content::before {
	content: '';
	display: block;
	position: absolute;
	right: -40px;
	width: $sidebar__width_active;
	border-top: 4px solid $active_background_color;
	pointer-events: none;
}

.insert-before > .content::before {
	top: -2px;
}

.insert-inside > .content {
	background: $active_background_color;
}

.insert-after > .content::before {
	bottom: -2px;
}
