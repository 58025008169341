
					@import './src/sass/variables';
					@import './src/sass/mixins';
				




























































































































.block-area::v-deep {
	flex-grow: 1;
	display: flex;
	flex-flow: column;
	padding-top: $site__padding/2;
	user-select: none;

	& > span {
		flex-grow: 1;

		&:empty {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: $site__padding;
			margin: 0 $site__padding * 2;
			border: 2px dashed $border_color;

			&::before {
				content: 'Drop a block here';
			}
		}
	}

	.legend.sortable-ghost {
		position: relative;
		z-index: 1;
		border: 10px solid transparent;
		border-left-color: $color__link;
		border-right-color: $color__link;
		height: 22px;
		margin: -11px 0;
		padding: 0;
		overflow: hidden;
		transition: transform 0s !important;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 2px;
			background: $color__link;
		}
	}

	.block {
		&.sortable-ghost {
			background: rgba($color__dark, 0.05);
		}
	}

	.sortable-drag {
		cursor: grabbing;
	}

	.question:not(.sortable-ghost)::before {
		display: none;
	}
}
