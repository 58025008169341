
					@import './src/sass/variables';
					@import './src/sass/mixins';
				















































































.home.view {
	width: 100%;
	max-width: 1360px;
	margin: 0 auto;

	& > .notices {
		position: absolute;
		bottom: 60px;
		left: 60px;
		list-style-type: none;
		z-index: 1000;
	}
}

.header::v-deep {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	column-gap: $default_padding;
	margin-bottom: $default_padding;

	button {
		line-height: 2em;
		padding: 0 12px;
	}

	h2 {
		margin-bottom: 0;
	}

	& > * {
		flex: 0 1 auto;
	}
}

@include breakpoint-min(medium) {
	.classes {
		column-count: 2;
		column-gap: 0;
		margin: $default_padding / -1;
	}

	.card::v-deep {
		break-inside: avoid;
		display: inline-block;
		width: 100%;
		margin-bottom: 0;
		padding: $default_padding;
	}
}
