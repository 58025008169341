
					@import './src/sass/variables';
					@import './src/sass/mixins';
				

























































































article {
	position: relative;

	&.locked {
		pointer-events: none;
	}

	&.regular-style {
		border-top: $border;
	}

	& > .block-inner {
		padding: $site__padding $site__padding * 2;
	}

	& > .feedback {
		padding: 0 $site__padding * 2 $site__padding;
	}

	&::v-deep header {
		margin-bottom: 1.5rem;
		position: relative;
	}

	&.question::v-deep {
		header {
			@include question-counter();
		}
	}

	&:first-child,
	&.content + .regular-style.content,
	&.text:not(.regular-style) + article {
		border-top: none;
	}

	&.content.regular-style  + .regular-style.content {
		& > * {
			padding-top: 0;
		}
	}
}

.feedback {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	& > svg {
		margin-right: 5px;

		&.icon-locked {
			margin-right: 10px;
		}
	}
}

.icon-passed,
.icon-passed ~ span {
	color: $color__green;
}

.icon-failed,
.icon-failed ~ span {
	color: $color__red;
}

.cefr {
	margin-left: $default_padding;
}

@include breakpoint('small') {
	article {
		& > .block-inner {
			padding: $site__padding;
		}

		&.question::v-deep {
			header {
				position: static;

				&::before {
					top: $default_padding * 0.5;
					left: $default_padding * 0.5;
					transform: none;
					font-size: 2em;
				}
			}
		}
	}
}
