
					@import './src/sass/variables';
					@import './src/sass/mixins';
				


























































































.overview-wrapper {
	display: flex;
	flex-flow: column nowrap;
	flex: 1;
	padding: $default_padding $default_padding * 2;

	@include breakpoint-min('small') {
		padding: $default_padding * 2 $default_padding * 4;
	}
}

h3 {
	font-size: 20px;
	margin-bottom: 0.5em;
}

table {
	margin: 0;

	th, td {
		border: none;
		padding: 0;

		@media (max-width: 535px) {
			display: block;
		}
	}

	th {
		padding: 0 15px 5px 0;
		font-weight: $font__semibold;
	}

	@media (max-width: 535px) {
		th {
			padding-right: 0;
		}

		td {
			padding-bottom: 15px;
		}
	}
}

ul {
	list-style-type: none;
	padding: 0;

	li {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: flex-start;
		gap: $default_padding;
		margin-bottom: $default_padding;
		padding: $default_padding;
		box-shadow: $box_shadow;
		background: $color__white;
		border-radius: $border_radius;

		@media (min-width: 535px) {
			flex-flow: row nowrap;
			align-items: center;
		}
	}

	@include breakpoint-min('large') {
		display: flex;
		flex-flow: row wrap;
		column-gap: $default_padding;

		li {
			flex: 1 0 calc(50% - #{$default_padding});
			max-width: calc(50% - #{$default_padding});
		}
	}
}
