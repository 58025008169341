
					@import './src/sass/variables';
					@import './src/sass/mixins';
				












































.button {
	display: inline-block;

	a, button {
		border-radius: $border_radius;
		line-height: 40px;
		padding: 0 1rem;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		text-decoration: none;
		font-weight: $font__semibold;
		transition: background-color 0.2s ease-out;
		cursor: pointer;

		&:focus {
			outline: none;
		}
	}

	&.reverse {
		a, button {
			flex-direction: row-reverse;
		}

		svg + span,
		span + svg {
			margin-right: 5px;
		}
	}

	&:not(.reverse) {
		svg + span,
		span + svg {
			margin-left: 5px;
		}
	}

	&.primary {
		a, button {
			background-color: $color__light_blue;
			color: $color__white;

			&:hover {
				background-color: rgba($color__light_blue, 0.8);
			}
		}
	}

	&.secondary {
		a, button {
			background-color: transparent;
			color: $color__text;
		}
	}

	&.delete {
		a, button {
			background-color: transparent;
			color: $color__red;
		}
	}

	&.link {
		a, button {
			background-color: transparent;
			color: $color__link;
			padding: 0;
		}
	}

	&.rounded {
		a, button {
			border-radius: 50%;
			padding: 10px;
		}
	}

	&.outlined {
		a, button {
			background-color: transparent;
			color: $color__light_blue;
			border: 1px solid $color__light_blue;

			&:hover {
				background-color: rgba($color__light_blue, 0.8);
				color: $color__white;
			}
		}
	}

	&.upload {
		input {
			display: none;
		}
	}

	&.disabled {
		opacity: 0.5;
		filter: saturate(0);
		pointer-events: none;
		cursor: default;
	}
}

.dg-container .dg-btn {
	border-radius: $border_radius;
	line-height: 40px;
	padding: 0 1rem;
	text-decoration: none;
	font-weight: $font__semibold;
	transition: background-color 0.2s ease-out;
	background-color: $color__light_blue;
	color: $color__white;
	border: none;

	&.dg-btn--cancel {
		background-color: transparent;
		color: $color__text;
	}

	&.dg-btn--ok:hover {
		background-color: rgba($color: $color__light_blue, $alpha: 0.8);
	}

	&:focus {
		outline: none;
	}
}
