
					@import './src/sass/variables';
					@import './src/sass/mixins';
				












































































































































































.single-course {
	flex: 1;
	display: flex;
	flex-flow: column;

	&.show-feedback::v-deep {
		.block.question {
			.block-inner {
				padding-bottom: $site__padding / 2;
			}
		}
	}

	&.hide-numbers::v-deep {
		article.question header::before {
			display: none;
		}
	}
}

main::v-deep {
	flex: 1;
	display: flex;
	flex-flow: column;
	padding: $site__padding 0 $site__padding;

	p,
	ol,
	ul {
		margin-bottom: $line__height;

		&:last-child {
			margin-bottom: 0;
		}
	}

	table p {
		margin-bottom: 0;
	}

	@include breakpoint('small') {
		padding: $site__padding 0 0;

		.content-footer .secondary.button {
			display: none;
		}
	}
}

.notices {
	position: fixed;
	left: calc(#{$sidebar__width_active} + #{$site__padding});
	bottom: $site__padding;
	z-index: 100;
}

.content-footer {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	border-top: $border;
	padding: $site__padding $site__padding * 2 0;

	.logo-wrapper {
		padding: 0;

		@include breakpoint('small') {
			display: none;
		}
	}

	@include breakpoint('small') {
		padding: $site__padding / 2 $site__padding / 2;
	}
}

.single-course.is-quiz::v-deep {
	.quiz-timer {
		position: fixed;
		width: calc(100% - var(--sidebar-width));
		left: var(--sidebar-width);
		bottom: $site__padding * 3 + 1;
		z-index: 1;

		@include breakpoint('small') {
			bottom: $site__padding * 2 + 1;
		}
	}

	.content-footer {
		position: fixed;
		width: calc(100% - var(--sidebar-width));
		left: var(--sidebar-width);
		bottom: 0;
		background: $color__white;
		padding-bottom: $site__padding;

		@include breakpoint('small') {
			padding: $site__padding / 2 $site__padding / 2;
		}
	}

	main {
		padding-bottom: $site__padding * 3;
	}

	.sidebar.active + main {
		.content-footer, .quiz-timer {
			width: calc(100% - var(--sidebar-width-active));
			left: var(--sidebar-width-active);
		}
	}
}

nav {
	display: flex;
	flex-flow: row nowrap;
	margin-left: auto;

	.saved-blocks-button {
		margin-right: $default_padding * 1.5;
	}

	.button::v-deep {
		margin-left: 10px;
	}
}

