
					@import './src/sass/variables';
					@import './src/sass/mixins';
				


































































































































































































































































































































ol {
	padding-left: 0;
}

.dragging::v-deep .router-link-exact-active {
	&::after {
		content: none;
	}
}
