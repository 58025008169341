
					@import './src/sass/variables';
					@import './src/sass/mixins';
				






































































































































$_course_sidebar_item_height: $font__size * 2;
$_course_sidebar_border_color: rgba(
	$color: $color__white,
	$alpha: 0.3,
);

.course-sidebar {
	user-select: none;
}

h1 {
	cursor: pointer;
}

header {
	padding: $sidebar__padding $sidebar__padding * 2;
	position: relative;
}

.progress-bar::v-deep {
	position: absolute;
	bottom: 0;
	left: 0;
	margin: 1.75em 0 0;
	width: 100%;
	background-color: $color__semi_white;

	& > div {
		color: $color__semi_white2;
		background-color: $color__white;

		span {
			top: -2em;
			font-size: 0.85rem;
		}
	}
}

.settings {
	position: absolute;
	left: $default_padding * 2;
	color: $color__semi_white2;
	cursor: pointer;

	&:hover {
		color: $color__white;
	}

	svg {
		display: block;
	}
}
