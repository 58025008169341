
					@import './src/sass/variables';
					@import './src/sass/mixins';
				







.login-layout {
	height: calc(100vh - #{$site__padding * 2});
	padding: $site__padding;
	flex: 0 1 auto !important;
}
