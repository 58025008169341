
					@import './src/sass/variables';
					@import './src/sass/mixins';
				















































.bool-input {
	flex: 0 0 20px;
	display: inline-flex;
	flex-flow: row nowrap;
	margin-right: 10px;
	margin-bottom: 10px;
	line-height: 1.25em;

	.input {
		position: relative;
		display: inline-block;
		background-color: $color__white;
		box-shadow: $box_shadow;
		height: 20px;
		width: 20px;
		margin-right: 5px;
		flex: 0 0 20px;

		input {
			position: absolute;
			visibility: hidden;
		}
	}

	&.checkbox .input {
		border-radius: $border_radius;
	}

	&.radio .input {
		border-radius: 50%;
	}

	svg {
		display: none;
	}

	input:checked + svg {
		position: absolute;
		top: -2px;
		left: 4px;
		display: block;
		color: $color__light-blue;
	}
}
