.collapse-enter-active,
.collapse-leave-active {
	transition: 400ms;
}

.collapse-enter,
.collapse-leave-to {
	opacity: 0;
	max-height: 0;
}

.collapse-enter-to,
.collapse-leave {
	opacity: 1;
	max-height: 100vh;
}